<template>
    <div class="schedaAttivita">
        <div class="container">
            <div class="row">
                <div class="col-2">
                    <div v-if="appartenenza === 'Baggi'">
                        <img src="/img/logo_baggi_lux.png" style="height: 50px"/>
                    </div>
                    <div v-else>
                        <img src="/img/logo_lacelle.png" style="height: 50px"/>
                    </div>
                </div>
                <div class="col-8 text-center">
                    <b>RAPPORTO DI LAVORO</b><br>
                    norme tecniche di riferimento uni 9494-3
                </div>
                <div class="col-2">
                    <div v-if="appartenenza === 'Baggi'" class="row">
                        <div class="col-6" style="line-height: 0.8">
                            <small>
                                Modulo <br> Proc. di rif. <br> Pagina
                            </small>
                        </div>
                        <div class="col-6" style="line-height: 0.8">
                            <small>
                                <b>07M01L-1 <br> 07P01 <br> 1 di 1</b>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <b>Data di emissione</b>
                </div>
                <div class="col-2">
                    <span v-if="data_scadenza">{{ data_scadenza }}</span>
                </div>
                <div class="col-4">
                    <span v-if="appartenenza === 'Baggi'">Coordinatore Baggi Lux</span>
                    <span v-else>Coordinatore La celle</span>
                </div>
                <div class="col-3">
                    <span v-if="appartenenza === 'Baggi'">Sig. Luca Collini</span>
                    <span v-else>Colaluce leonardo</span>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <b>Cliente</b>
                </div>
                <div class="col-4">
                    <span v-if="full_cliente && full_cliente.nominativo1">
                        <b>{{ full_cliente.nominativo1 }}</b>
                    </span>
                </div>
                <div class="col-5"></div>
                <div class="col-3">
                    <b>Indirizzo</b>
                </div>
                <div class="col-4">
                    <span v-if="full_stabilimento && full_stabilimento.indirizzo">
                        <b>{{ full_stabilimento.indirizzo }}</b>
                    </span>
                </div>
                <div class="col-5"></div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b v-if="tipo_attivita">Intervento richiesto per {{ tipo_attivita }}</b>
                </div>
                <div class="col-12" style="padding-top: 0">
                    <div v-if="full_stabilimento && full_stabilimento.frequenza_controlli">
                        {{ full_stabilimento.frequenza_controlli }}
                    </div>
                </div>
            </div>
            <div v-if="note_aggiuntive" class="row">
                <div class="col-12">
                    <b>ISTRUZIONI E NOTE PER L’INTERVENTO</b>
                </div>
                <div class="col-12" style="padding-top: 0">
                    {{ note_aggiuntive }}
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <b v-if="appartenenza === 'Baggi'">Firma Resp. Baggi Lux</b>
                    <b v-else>Firma Resp. La celle</b>
                    <img v-if="appartenenza === 'Baggi'" src="/img/baggi.png" style="height: 100px" />
                    <img v-else src="/img/Cerutti.jpg" />
                </div>
                <div class="col-9">
                    <b>Note/Allegati</b>
                    <span v-if="note"></span>
                </div>
                <div class="col-12">
                    <b>VERIFICA PROCEDURE</b>
                </div>
                <div class="col-12" style="padding-top: 0">
                    <b>
                        Registrazione dei controlli e delle attività svolte su apposito sistema gestionale della totalità <br>
                        degli evacuatori presenti, secondo la normativa UNI 9494-3.<br>
                        Nell’esecuzione dei lavori, è garante dell’osservanza scrupolosa della “regola d’arte” e delle <br>
                        norme vigenti. <br>
                        Sottoscritto dal tecnico manutentore qualificato.<br>
                    </b>
                    Documenti allegati:<br>
                    Registro Controlli periodici E.N.F.C<br>
                    Accesso al gestionale di manutenzione online
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span v-if="data_scadenza">Scadenza {{ data_scadenza }}</span>
                </div>
                <div class="col-12 text-left">
                    <b>TECNICO MANUTENTORE QUALIFICATO</b>
                </div>
                <div v-if="assegnazione && assegnazione[0] && assegnazione.includes('607fd4e83e8094ff9552c9e2')" class="col-4">
                    <img src="/img/Cerutti.jpg" style="height: 100px" />
                </div>
                <div v-if="assegnazione && assegnazione[0] && assegnazione.includes('608072f5f054d01a43411cd4')" class="col-4">
                    <img src="/img/Colaluce.jpg" style="height: 100px" />
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-left" style="margin-bottom: 150px">
                    <b>Firma cliente</b>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiUtenti from '@/js/pages/utenti';
import apiStabilimenti from '@/js/pages/stabilimenti';
import apiAttivita from '@/js/pages/attivita';
const NewDDT = () => import('@/components/NewDDT');
const NewRapporto = () => import('@/components/NewRapporto');
const ListaRapportini = () => import('@/components/Lista_rapportini');

export default {
    name: 'stampaAttivita',
    components: {
        NewDDT,
        ListaRapportini,
        NewRapporto
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            ruolo: this.$store.state.ruolo,
            id: this.$route.query._id,
            titolo: '',
            numero: '',
            note: '',
            note_aggiuntive: '',
            cliente: undefined,
            stabilimento: undefined,
            lista_tipi_attivita: ['Manutenzione', 'Posa', 'Controllo', 'Altro'],
            tipo_attivita: '',
            data_inizio: '',
            data_scadenza: '',
            isMaster: false,
            isTecnico: false,
            stato: '',
            stati_attivita: ['Nuova', 'In Elaborazione', 'Sospesa', 'Conclusa'],
            appartenenza: '',
            assegnazione: [],
            full_stabilimento: undefined,
            full_cliente: undefined
        }
    },
    watch: {
        async cliente() {
            if (this.token) {
                this.lista_stabilimenti = [];
                let lista_stabilimenti = await apiStabilimenti.getAll(this.token, this.cliente);
                this.lista_stabilimenti = lista_stabilimenti || [];
                if (
                    lista_stabilimenti &&
                    lista_stabilimenti.length === 1 &&
                    !this.stabilimento
                ) this.stabilimento = lista_stabilimenti[0]['_id'];
            }
        },
        ruolo() {
            if (this.ruolo === 'Partner') {
                this.appartenenza = 'Baggi';
                this.lista_permessi = ['Cliente'];
            }
        }
    },
    async created() {
        await this.initAttivita();
    },
    methods: {
        async initAttivita() {
            if (this.$store.state.ruolo) {
                if (this.$store.state.ruolo === 'Cliente') this.isMaster = false;
                else this.isMaster = true;
                if (this.$store.state.ruolo === 'Tecnico') this.isTecnico = false;
                else this.isTecnico = true;
            } else {
                this.isMaster = false;
                this.isTecnico = false;
            }
            if (this.token) {
                if (this.ruolo === 'Partner') {
                    this.appartenenza = 'Baggi';
                    this.lista_permessi = ['Cliente'];
                    this.lista_clienti = await apiUtenti.getAllClientPartner(this.token, 'Baggi');
                } else this.lista_clienti = await apiUtenti.getAll(this.token, 'Cliente');
            }

            if (!this.id) this.id = this.$route.query._id;
            let attivita = await apiAttivita.getOne(this.id);
            console.log(attivita)
            this.numero = attivita.numero || '';
            this.titolo = attivita.titolo || '';
            this.note = attivita.note || '';
            this.note_aggiuntive = attivita.note_aggiuntive || '';
            this.stato = attivita.stato || '';
            this.cliente = attivita.id_cliente;
            if (
                attivita.assegnazione &&
                attivita.assegnazione[0]
            ) this.assegnazione = attivita.assegnazione;
            if (attivita.id_cliente) {
                let utente = await apiUtenti.getUtenteFromId(attivita.id_cliente);
                if (utente) this.lista_clienti = [utente];
            }
            this.stabilimento = attivita.id_stabilimento;
            if (
                attivita.full_stabilimento &&
                attivita.full_stabilimento[0]
            ) this.full_stabilimento = attivita.full_stabilimento[0];
            if (
                attivita.full_cliente &&
                attivita.full_cliente[0]
            ) this.full_cliente = attivita.full_cliente[0];

            this.tipo_attivita = attivita.tipo_attivita;
            this.data_inizio = attivita.data_inizio ? new Date(attivita.data_inizio).toISOString().substr(0, 10) : '';
            this.data_scadenza = attivita.data_scadenza ? new Date(attivita.data_scadenza).toISOString().substr(0, 10) : '';
            this.appartenenza = attivita.appartenenza || 'LaCelle';
        }
    }
}
</script>
